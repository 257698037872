<template>
    <div>
        <CDataTable
            :fields="detailTableFields"
            :items="orderDetail"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            :pagination="true"
            v-on:refresh="productionOrdersDetail()"
        >
            <template #sku="{item}">
              <td>{{ item.sku.name}} </td>
            </template>
            <template #productName="{item}">
              <td>{{ item.sku.product.name}} </td>
            </template>
        </CDataTable>
    </div>
</template>

<script>

    export default{
        name: "OrderDetailList",
        props:{
            params: Object,
        },
        computed:{
            loading: function() {
                return this.$store.getters.productionLoading
            },
            orderDetail: function() {
                return this.$store.getters.orderDetail
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
                this.productionOrdersDetail()
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                detailTableFields: [
                    {key: 'id', label: "ID"},
                    {key: 'sku', label: 'SKU', _classes: 'font-weight-bold'},
                    {key: 'productName', label: 'Ürün', _classes: 'font-weight-bold'},
                    {key: 'amount', label: 'Adet'},
                ],
            }
        },
        mounted: function(){
            this.productionOrdersDetail()
        },
        methods: {
            productionOrdersDetail: async function(){
                await this.$store.dispatch('productionOrderDetail_list', {productionOrderId: this.data.id})
            },
        }
    }
</script>